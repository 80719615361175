// src/components/Services.js
import React from 'react';
import styled from 'styled-components';

const ServicesWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h1, h2, h3 {
    color: #006400;
    text-align: center;
  }

  p, ul {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
`;

const Services = () => {
  return (
    <ServicesWrapper>
      <h1>Our Services</h1>
      <h2>UPSTREAM</h2>
      <h3>A. Provision Of Drilling Services</h3>
      <ul>
        <li>Drilling Rigs</li>
        <li>Drilling Fluids</li>
        <li>Production Chemicals</li>
        <li>Waste Management Control And Equipments</li>
      </ul>
      <h3>B. Mechanical Construction</h3>
      <ul>
        <li>Fabrication services</li>
      </ul>
      <h3>C. Asset Integrity Management</h3>
      <ul>
        <li>Pipelines Services and Monitoring</li>
      </ul>
      <h3>D. Equipment Leasing</h3>
      <ul>
        <li>Heavy duty equipments</li>
        <li>Earth moving equipments</li>
        <li>Vehicles</li>
      </ul>
      <h3>E. Strategic Procurement</h3>
      <ul>
        <li>Cost-effective and efficient solutions that ensure significant cost savings.</li>
        <li>Relationships with Original Equipment Manufacturers.</li>
      </ul>
      <h2>DOWNSTREAM</h2>
      <h4>A. Marketing, Supply & Trading of the following : </h4>
      <p>This business unit is responsible for the supply and trading of crude and petroleum products.</p>
      <ul>
        <li>Products traded include:</li>
        <ul>
          <li>Premium Motor Spirit (PMS)</li>
          <li>Automotive Gas Oil (AGO)</li>
          <li>Dual Purpose Kerosene (DPK)</li>
          <li>Liquefied Petroleum Gas (LPG)</li>
          <li>Aviation Turbine Kerosene (ATK)</li>
          <li>Low Pour Fuel Oil (LPFO)</li>
        </ul>
      </ul>
    </ServicesWrapper>
  );
};

export default Services;

