// src/components/Home.js
import React from 'react';
import styled from 'styled-components';

const HomeWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h1, h2, h3 {
    color: #006400;
    text-align: center;
  }

  p, ul {
    margin-bottom: 20px;
    padding-left: 10px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Home = () => {
  return (
    <HomeWrapper>
      <h1>Welcome to Greyegg Oil & Gas Ltd</h1>
      <h2>VISION</h2>
      <p>
        To be a leading global oil and gas company, providing sustainable and reliable energy solutions to meet the world's growing demand.
      </p>
      <h2>MISSION</h2>
      <p>
        Our mission is to responsibly explore, develop, and produce oil and gas resources in a safe and environmentally sustainable manner.
      </p>

      <h2>CORE VALUES</h2>
      <ul>
        <li>Excellence</li>
        <li>Professionalism</li>
        <li>Innovation</li>
        <li>Corporate Social Responsibility</li>
        <li>Integrity</li>
        <li>Teamwork</li>
      </ul>

      <h2>STRATEGY</h2>
      <ul>
        <li>Strengthen client base by ensuring excellence and innovation in service delivery.</li>
        <li>Enhance capability using the "build-borrow-buy" growth pathway.</li>
        <li>Create strategic relationships for superior delivery.</li>
        <li>Recruit, retain, and reward high-performance employees.</li>
      </ul>

      <h2>COMMUNITY SERVICE</h2>
      <ul>
        <li>Integrated planning and execution of community development programs.</li>
        <li>Sustainable empowerment of indigenous host communities.</li>
        <li>Adoption of best practices that guarantee community-friendly operations.</li>
      </ul>

      <p>
        We believe our success is linked to the health and safety of our employees and clients, as well as our reputation as a leader in environmental protection.
      </p>
    </HomeWrapper>
  );
};

export default Home;

