import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4; /* Light background */
    color: #333; /* Dark text */
  }

  h1, h2, h3 {
    margin: 0;
    padding: 10px;
  }

  a {
    text-decoration: none;
    color: #006400; /* Link color */
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding-left: 20px;
  }
`;

