// src/theme.js
export const theme = {
  colors: {
    primary: '#006400', // Dark Green
    secondary: '#8b0000', // Dark Red
    success: '#FFD700', // Gold
    accent: '#000000', // Black
  },
  fonts: {
    main: 'Arial, sans-serif',
  },
};


