// src/components/Header.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import headerImage from '../assets/header-image.jpg';
import logo from '../assets/logo.png';

const HeaderWrapper = styled.header`
  background-image: url(${headerImage});
  background-size: cover;
  background-position: center;
  padding: 20px;
  text-align: center;
  color: white;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
  }

  .logo {
    font-size: 2em;
    font-weight: bold;
    img {
      height: 50px;
    }
  }

  nav {
    margin-top: 10px;

    a {
      color: white;
      margin: 0 10px;
      font-size: 1.2em;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <div className="overlay"></div>
      <div className="content">
        <div className="logo">
          <img src={logo} alt="Greyegg Oil & Gas Ltd" />
        </div>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/services">Services</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/contact">Contact</Link>
        </nav>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

