import React from 'react';
import styled from 'styled-components';

const AboutWrapper = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  h1, h2 {
    color: #006400;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    font-size: 1.1em;
    color: #333;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 15px;
      position: relative;
      padding-left: 20px;
      
      &::before {
        content: '✔️';
        position: absolute;
        left: 0;
        color: #006400;
      }
    }
  }
`;

const About = () => {
  return (
    <AboutWrapper>
      <h1>About Us</h1>
      <p>
        GREYEGG Oil and Gas Company is a leading player in the oil and gas industry, with a strong focus on exploration, production, and distribution of oil and gas resources.
      </p>
      <p>
        Founded in 2017, GREYEGG Oil and Gas Company has grown to become a prominent name in the industry, with operations spanning across Nigeria.
      </p>
      
      <h2>Services and Offerings</h2>
      <ul>
        <li>
          <strong>Exploration and Production:</strong> Strong track record in oil and natural gas exploration and production.
        </li>
        <li>
          <strong>Refining and Distribution:</strong> Operates refineries and distribution networks for efficient processing and delivery.
        </li>
        <li>
          <strong>Renewable Energy:</strong> Actively investing in renewable energy projects, including solar and wind energy.
        </li>
      </ul>

      <h2>Key Differentiators</h2>
      <ul>
        <li>
          <strong>Technological Innovation:</strong> At the forefront of technological advancement in the industry.
        </li>
        <li>
          <strong>Environmental Stewardship:</strong> Committed to minimizing environmental footprint through sustainable practices.
        </li>
        <li>
          <strong>Global Presence:</strong> Operations in multiple countries ensuring diverse and resilient business practices.
        </li>
        <li>
          <strong>Corporate Social Responsibility:</strong> Dedicated to making a positive impact in local communities.
        </li>
      </ul>

      <p>
        Overall, GREYEGG Oil and Gas Company is a forward-thinking and responsible player in the oil and gas industry, with a strong focus on sustainability.
      </p>

      <h2>Our People</h2>
      <p>
        GREYEGG Oil and Gas has a dynamic workforce, which we refer to as our greatest strength. Our highly competent management team ensures a high-performance environment.
      </p>
      <p>
        We strive to create an environment that supports professionalism, integrity, and innovation, ultimately enhancing our client relationships through excellence in service delivery.
      </p>
    </AboutWrapper>
  );
};

export default About;

