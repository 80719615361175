import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const BlogWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
`;

const BlogPost = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-weight: bold;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
`;

const Blog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [limit] = useState(5); // Number of posts to show per page
  const [filter, setFilter] = useState("");

  const apiKey = process.env.REACT_APP_EIA_API_KEY;
  const endpoints = [
    `https://api.eia.gov/v2/petroleum/pri/spt/data/?api_key=${apiKey}`,
    `https://api.eia.gov/v2/natural-gas/pri/sum/data/?api_key=${apiKey}`,
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!apiKey) {
        setError("API key is missing. Please set REACT_APP_EIA_API_KEY.");
        setLoading(false);
        return;
      }

      let allData = [];

      try {
        for (const endpoint of endpoints) {
          let accumulatedData = [];
          let offset = 0;

          while (true) {
            const response = await fetch(`${endpoint}&offset=${offset}`);

            console.log(`Fetching: ${endpoint}&offset=${offset}`);
            if (!response.ok) {
              throw new Error(`Error fetching ${response.url}: ${response.statusText}`);
            }

            const result = await response.json();
            console.log('Fetched data:', result);

            if (result.response && result.response.data) {
              accumulatedData = [...accumulatedData, ...result.response.data];
            } else {
              break;
            }

            if (result.response.data.length < 5000) {
              break;
            }

            offset += 5000;
          }

          allData.push({
            title: `Data from ${endpoint.split('/')[4]}`,
            data: accumulatedData,
          });
        }

        setData(allData);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiKey]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setPage(0); // Reset to first page when filter changes
  };

  const filteredData = data.flatMap(post =>
    post.data.filter(item => 
      item['area-name'].toLowerCase().includes(filter.toLowerCase())
    ).map(item => ({ ...item, title: post.title }))
  );

  const paginatedData = filteredData.slice(page * limit, (page + 1) * limit);
  const totalPages = Math.ceil(filteredData.length / limit);

  return (
    <BlogWrapper>
      <h2>Blog</h2>
      <FilterContainer>
        <input
          type="text"
          placeholder="Filter by Area Name"
          value={filter}
          onChange={handleFilterChange}
        />
      </FilterContainer>
      {loading && <LoadingSpinner />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!loading && !error && paginatedData.map((post, index) => (
        <BlogPost key={index}>
          <h3>{post.title}</h3>
          <p><strong>Period:</strong> {post.period}</p>
          <p><strong>Area:</strong> {post['area-name']}</p>
          <p><strong>Product:</strong> {post['product-name']}</p>
          <p><strong>Process:</strong> {post['process-name']}</p>
          <p><strong>Series Description:</strong> {post['series-description']}</p>
        </BlogPost>
      ))}
      <div>
        <button 
          disabled={page === 0} 
          onClick={() => setPage(page - 1)}
        >
          Previous
        </button>
        <span>{` Page ${page + 1} of ${totalPages} `}</span>
        <button 
          disabled={page >= totalPages - 1} 
          onClick={() => setPage(page + 1)}
        >
          Next
        </button>
      </div>
    </BlogWrapper>
  );
};

export default Blog;

