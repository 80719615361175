// src/components/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <section className="not-found-section">
      <h2>404 - Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </section>
  );
};

export default NotFound;
